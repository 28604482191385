<template>
  <div class="w-100 h-100 overflow-auto">
    <d-remote-monitoring-scoring-rules />
  </div>
</template>

<script>

export default {
  name: 'RemoteMonitoringScoringRules',
  page: {
    title: 'CRM Doctis - Шаблоны правил оценки ДМ',
  },
};
</script>

<style lang="scss" scoped>
  ::v-deep.bg-white .td-button {
    display: flex;
    justify-content: space-around;
  }
</style>
