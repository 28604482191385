import { render, staticRenderFns } from "./RemoteMonitoringScoringRules.vue?vue&type=template&id=70acdb66&scoped=true"
import script from "./RemoteMonitoringScoringRules.vue?vue&type=script&lang=js"
export * from "./RemoteMonitoringScoringRules.vue?vue&type=script&lang=js"
import style0 from "./RemoteMonitoringScoringRules.vue?vue&type=style&index=0&id=70acdb66&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70acdb66",
  null
  
)

export default component.exports